import React from 'react';
import SendCardSuccessful from '../../components/updated/Redeem/CardSentSuccessfully';

const SendCardSuccessfulPage = () => {
  return (
    <div>
      <SendCardSuccessful />
    </div>
  );
};

export default SendCardSuccessfulPage;
