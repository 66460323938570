import React from 'react';
import Convert from '../components/updated/Convert';
const ConvertPage = () => {
  return (
    <div>
      <Convert />
    </div>
  );
};

export default ConvertPage;
