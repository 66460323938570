import React from 'react';
import PaypalPartnership from '../components/updated/BuySell/BuyCrypto/PaypalBridge';
const PaypalPartnershipPage = () => {
  return (
    <div>
      <PaypalPartnership />
    </div>
  );
};

export default PaypalPartnershipPage;
