import React from 'react';
import SendCard from '../../components/updated/Redeem/SendCard';

const SendCardPage = () => {
  return (
    <div>
      <SendCard />
    </div>
  );
};

export default SendCardPage;
