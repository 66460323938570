import React from 'react';
import Redeem from '../../components/updated/Redeem';

const RedeemPage = () => {
  return (
    <div>
      <Redeem />
    </div>
  );
};

export default RedeemPage;
