import React from 'react';
import CreateCard from '../../components/updated/Redeem/CreateCards';

const CreateCardPage = () => {
  return (
    <div>
      <CreateCard />
    </div>
  );
};

export default CreateCardPage;
